import { ArrowUpTrayIcon, DocumentTextIcon, XMarkIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import Dropzone, { type DropzoneProps, type FileRejection } from 'react-dropzone';

import { Button } from '@/components/ui/button';
import { useControllableState } from '@/components/ui/use-controllable-state';
import { cn, formatBytes } from '@/lib/utils';
import { toast } from './use-toast';

interface FileUploaderProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Value of the uploader.
   * @type File[]
   * @default undefined
   * @example value={files}
   */
  value?: File[];

  /**
   * Function to be called when the value changes.
   * @type (files: File[]) => void
   * @default undefined
   * @example onValueChange={(files) => setFiles(files)}
   */
  onValueChange?: (files: File[]) => void;

  /**
   * Accepted file types for the uploader.
   * @type { [key: string]: string[]}
   * @default
   * ```ts
   * { "image/*": [] }
   * ```
   * @example accept={["image/png", "image/jpeg"]}
   */
  accept?: DropzoneProps['accept'];

  /**
   * Maximum file size for the uploader.
   * @type number | undefined
   * @default 1024 * 1024 * 2 // 2MB
   * @example maxSize={1024 * 1024 * 2} // 2MB
   */
  maxSize?: DropzoneProps['maxSize'];

  /**
   * Maximum number of files for the uploader.
   * @type number | undefined
   * @default 1
   * @example maxFileCount={4}
   */
  maxFileCount?: DropzoneProps['maxFiles'];

  /**
   * Whether the uploader should accept multiple files.
   * @type boolean
   * @default false
   * @example multiple
   */
  multiple?: boolean;

  /**
   * Whether the uploader is disabled.
   * @type boolean
   * @default false
   * @example disabled
   */
  disabled?: boolean;
}

export function FileUploader(props: FileUploaderProps) {
  const {
    value: valueProp,
    onValueChange,
    accept = {
      'image/*': [],
    },
    maxSize = 1024 * 1024 * 2,
    maxFileCount = 1,
    multiple = false,
    disabled = false,
    className,
    ...dropzoneProps
  } = props;

  const [files, setFiles] = useControllableState({
    prop: valueProp,
    onChange: onValueChange,
  });

  const onDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (!multiple && maxFileCount === 1 && acceptedFiles.length > 1) {
        toast({
          title: 'Cannot upload more than 1 file at a time',
          variant: 'destructive',
        });
        return;
      }

      if ((files?.length ?? 0) + acceptedFiles.length > maxFileCount) {
        toast({ title: `Cannot upload more than ${maxFileCount} files`, variant: 'destructive' });
        return;
      }

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      const updatedFiles = files ? [...files, ...newFiles] : newFiles;

      setFiles(updatedFiles);

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(({ file }) => {
          toast({ title: `File ${file.name} was rejected`, variant: 'destructive' });
        });
      }
    },

    [files, maxFileCount, multiple, setFiles]
  );

  function onRemove(index: number) {
    if (!files) return;
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    onValueChange?.(newFiles);
  }

  const isDisabled = disabled || (files?.length ?? 0) >= maxFileCount;

  return (
    <div className='relative flex flex-col gap-6 overflow-hidden'>
      {!files?.length || (files?.length ?? 0) < maxFileCount ? (
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          maxSize={maxSize}
          maxFiles={maxFileCount}
          multiple={maxFileCount > 1 || multiple}
          disabled={isDisabled}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={cn(
                'group relative grid  w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-10 text-center transition hover:bg-muted/25',
                'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                isDragActive && 'border-muted-foreground/50',
                isDisabled && 'pointer-events-none opacity-60',
                className
              )}
              {...dropzoneProps}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                  <div className='p-3 border border-dashed rounded-full'>
                    <ArrowUpTrayIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                  </div>
                  <p className='font-medium text-muted-foreground'>Drop the files here</p>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center gap-4 sm:px-5'>
                  <div className='p-3 border border-dashed rounded-full'>
                    <ArrowUpTrayIcon className='size-7 text-muted-foreground' aria-hidden='true' />
                  </div>
                  <div className='flex flex-col gap-0.5'>
                    <p className='font-medium text-muted-foreground'>Drag {`'n'`} drop files here to start uploading</p>
                    <p className='font-medium text-muted-foreground/80'>or</p>
                    <p className='text-primary-600 underline'>Click here to browse files</p>

                    <p className='text-sm text-muted-foreground/70'>
                      You can upload
                      {maxFileCount > 1
                        ? ` ${maxFileCount === Infinity ? 'multiple' : maxFileCount}
                      files (up to ${formatBytes(maxSize)} each)`
                        : ` a file with ${formatBytes(maxSize)}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      ) : null}
      {files?.length ? (
        <div className='flex flex-col gap-4 max-h-48'>
          {files?.map((file, index) => <FileCard key={index} file={file} onRemove={() => onRemove(index)} />)}
        </div>
      ) : null}
    </div>
  );
}

interface FileCardProps {
  file: File;
  onRemove: () => void;
}

function FileCard({ file, onRemove }: FileCardProps) {
  return (
    <div className='relative flex items-center gap-2.5'>
      <div className='flex flex-1 gap-2.5'>
        <div className='flex flex-col w-full gap-2'>
          <div className='flex flex-col gap-px'>
            <p className='text-sm font-medium line-clamp-1 text-foreground/80'>{file.name}</p>
            <p className='text-xs text-muted-foreground'>{formatBytes(file.size)}</p>
          </div>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <Button type='button' variant='outline' size='icon' className='size-7' onClick={onRemove}>
          <XMarkIcon className='size-4' aria-hidden='true' />
          <span className='sr-only'>Remove file</span>
        </Button>
      </div>
    </div>
  );
}

interface FilePreviewProps {}

function FilePreview(props: FilePreviewProps) {
  return <DocumentTextIcon className='size-10 text-muted-foreground' aria-hidden='true' />;
}
