export const enum FILTER_KEY {
  PageSize = 'pageSize',
  PageNumber = 'pageNumber',
  MonitoringObserverStatus = 'monitoringObserverStatus',
  MonitoringObserverTags = 'tags',
  FormTypeFilter = 'formTypeFilter',
  FormStatusFilter = 'formStatusFilter',
  HasFlaggedAnswers = 'hasFlaggedAnswers',
  FormSubmissionFollowUpStatus = 'followUpStatus',
  HasNotes = 'hasNotes',
  MediaFiles = 'hasAttachments',
  QuestionsAnswered = 'questionsAnswered',
  LocationL1 = 'level1Filter',
  LocationL2 = 'level2Filter',
  LocationL3 = 'level3Filter',
  LocationL4 = 'level4Filter',
  LocationL5 = 'level5Filter',
  PollingStationNumber = 'pollingStationNumberFilter',
  FormSubmissionsMonitoringObserverTags = 'tagsFilter',
  ViewBy = 'viewBy',
  Tab = 'tab',
  FormId = 'formId',
  SortOrder = 'sortOrder',
  SortColumnName = 'sortColumnName',
  FromDate = 'submissionsFromDate',
  ToDate = 'submissionsToDate',
  SearchText = 'searchText',
  QuickReportIncidentCategory ='incidentCategory',
  QuickReportFollowUpStatus ='quickReportFollowUpStatus',
  HasQuickReports ='hasQuickReports',
  DataSource ='dataSource',
  CoalitionMemberId = 'coalitionMemberId',
}

export const enum FILTER_LABEL {
  MonitoringObserverStatus = 'Observer status',
  MonitoringObserverTags = 'Tags',
  FormTypeFilter = 'Form type',
  HasFlaggedAnswers = 'Flagged answers',
  FollowUpStatus = 'Follow-up status',
  HasNotes = 'Question notes',
  QuestionsAnswered = 'Questions answered',
  LocationL1 = 'Location - L1',
  LocationL2 = 'Location - L2',
  LocationL3 = 'Location - L3',
  LocationL4 = 'Location - L4',
  LocationL5 = 'Location - L5',
  PollingStationNumber = 'Polling station number',
  FormSubmissionsMonitoringObserverTags = 'Observer tags',
  MediaFiles = 'Has attachments',
  FormId = 'Form',
  FormStatus = 'Form status',
  FromDate = 'From date',
  ToDate = 'To Date',
  SearchText = 'Search text',
  QuickReportIncidentCategory ='Incident category',
  QuickReportFollowUpStatus ='Quick report follow up status',
  HasQuickReports ='Has quick reports',
  CoalitionMemberId = 'NGO'
}
